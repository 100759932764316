<template>
  <div class="contractEndPage">
    <base-section>
      <base-grid modifiers="maxWidth">
        <base-row>
          <base-column>
            <div v-if="!contractResponse">
              Loading...
            </div>

            <div v-else class="aboutyou">
              <div class="intro">
                <base-heading type="4">
                  {{ $t('contractEnd.title') }}
                </base-heading>

                <p v-html-safe="$t('contractEnd.title', { contractEndDate: contractResponse.end_date })" />
              </div>
            </div>
          </base-column>
        </base-row>
      </base-grid>
    </base-section>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BaseSection from '@/components/grid/BaseSection';
import BaseGrid from '@/components/grid/BaseGrid';
import BaseRow from '@/components/grid/BaseRow';
import BaseColumn from '@/components/grid/BaseColumn';
import BaseHeading from '@/components/BaseHeading';

export default {
  components: {
    BaseSection,
    BaseGrid,
    BaseRow,
    BaseColumn,
    BaseHeading,
  },

  computed: {
    ...mapState('user', ['contractResponse']),
  },

  mounted() {
    if (!this.contractResponse) {
      this.$router.push({ name: 'contract.questionnaire' });
    }
  },

  unmounted() {
    this.$store.commit('user/setContractResponse', null);
  },
};
</script>

<style lang="scss" scoped>
.contractEndPage {
  padding: 20px 0;
}
</style>
